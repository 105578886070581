<script setup>
import Card from "@/components/Tools/Card/Card.vue";
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import Loader from "@/components/Tools/Loader.vue";
import Progressbar from "@/components/Tools/Progressbar.vue";
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import { useWizard } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useWizard";
import { useStore } from "@/core/services/store";
import OAuth from "@/components/ExternalApps/SalesChannelManagementApp/components/OAuth.vue";
import HelpArea from "@/components/ExternalApps/SalesChannelManagementApp/components/HelpArea.vue";
import i18n from "@/core/plugins/vue-i18n";
import { useHelp } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useHelp";
import { loadForms } from "@/composables/useSalesChannelManagementApp";

const formComponent = ref(null);
const {
  currentStep,
  form,
  formConfig,
  integration,
  isSaving,
  logo,
  nextButtonDisabled,
  step,
  wizardSteps,
  validName,
  setBaseDataNameBlacklist,
  back,
  close,
  next,
  onChange,
  validateCredentials,
  validateSftpData,
  setIntegrationDefaultValues,
  getPreviousConfig
} = useWizard(formComponent);
const { getLinkForTypeAndArea } = useHelp();

const store = useStore();
const activeField = ref();
const switcher = ref({ checkbox: undefined });
const { authModeToggle, label } = store.getters.appConfig;

const helpItems = computed(() => [
  {
    href: getLinkForTypeAndArea("handbook", "wizard", false),
    icon: "fa-book",
    label: i18n.t("salesChannelManagementApp.helpArea.handbook")
  }
]);

watch(
  () => form.value,
  () => {
    if (!form.value) return;
    activeField.value = store.getters
      .form("wizard.baseData")
      .find(field => field.name === "active");

    activeField.value.type = "hidden";
  }
);

onMounted(async () => {
  await setBaseDataNameBlacklist();

  const xentralConfigData = store.getters.xentralConfig?.data ?? [];
  const shouldLoadPreviousConfig = !!(
    xentralConfigData["state"] && xentralConfigData["state"].length
  );

  if (!shouldLoadPreviousConfig) {
    await loadForms();
    setIntegrationDefaultValues();
  }

  await getPreviousConfig();
});

onUnmounted(() => (activeField.value.type = "checkbox"));
</script>

<template>
  <div class="wizard-container">
    <Progressbar />

    <div class="wizard">
      <Card class="wizard-card">
        <!--      START: LOADER      -->
        <div v-if="isSaving" class="wizard-loader p-6">
          <div class="alert alert-info">
            {{ $t("salesChannelManagementApp.saveIntegration") }}
          </div>
          <Loader />
        </div>
        <!--      END: LOADER      -->

        <!--      START: WIZARD      -->
        <template v-else>
          <div class="h-100 wizard-grid">
            <!--      START: WIZARD-SIDEBAR (LEFT)     -->
            <div class="side-navigation overflow-y-auto overflow-x-hidden">
              <div class="side-nav-content display-grid p-4">
                <div>
                  <div class="w-100">
                    <img :alt="logo" :src="logo" class="wizard-logo mb-4" />
                  </div>
                  <!--      START: STEPS-LISTING      -->
                  <div>
                    <div
                      v-for="(wizardStep, i) in wizardSteps"
                      :key="i"
                      :class="currentStep + 1 <= i ? 'inactive' : ''"
                      class=""
                    >
                      <div class="mb-3 row">
                        <!--      START: DOT/LINES      -->
                        <div class="wizard-dot-lines col-2">
                          <div class="text-center">
                            <img
                              alt=""
                              src="../../../assets/img/green-dot.svg"
                            />
                          </div>
                          <div
                            v-if="i !== wizardSteps.length - 1"
                            class="mt-2 position-relative"
                          >
                            <span class="dashed-line"></span>
                          </div>
                        </div>
                        <!--      END: DOT/LINES      -->
                        <!--      START: TITLE/DESCRIPTION      -->
                        <div class="col-10 pl-0">
                          <h6 class="font-weight-bold m-0">
                            {{
                              $t(
                                "salesChannelManagementApp.titles." + wizardStep
                              )
                            }}
                          </h6>
                          <span class="hyphen-auto">{{
                            $t(
                              "salesChannelManagementApp.descriptions." +
                                wizardStep
                            )
                          }}</span>
                        </div>
                        <!--      END: TITLE/DESCRIPTION      -->
                      </div>
                    </div>
                  </div>
                  <!--      END: STEPS-LISTING      -->
                </div>
              </div>
            </div>
            <!--      END: WIZARD-SIDEBAR      -->

            <!--      START: WIZARD-CONTENT (RIGHT)      -->
            <div
              class="display-grid wizard-content overflow-y-auto overflow-x-hidden"
            >
              <!--      START: GENERAL-CONTENT      -->
              <div class="p-4">
                <div class="wizard-header mb-4 d-flex justify-content-between">
                  <div>
                    <h6 class="font-weight-bold">
                      {{ formConfig.heading }}
                    </h6>
                    <span>{{ formConfig.description }}</span>
                  </div>
                  <button class="btn btn-icon btn-sm" @click="close">
                    <i class="fal fa-close" />
                  </button>
                </div>
                <!--       switch     -->
                <FormHelper
                  v-if="step === 'credentials' && authModeToggle"
                  v-model="switcher"
                  class="w-100"
                  :config="{
                    labelColClass: 'col-6',
                    fieldColClass: 'col-6',
                    fieldColClassXl: 'col-5'
                  }"
                  :form="[
                    {
                      type: 'checkbox',
                      name: 'checkbox',
                      default: true,
                      label: i18n.t('salesChannelManagementApp.authModeToggle'),
                      validations: { required: true },
                      infoText: i18n.t(
                        'salesChannelManagementApp.authModeToggleInfo',
                        { appName: label }
                      ),
                      switch: true
                    }
                  ]"
                />
                <!--       switch     -->
                <FormHelper
                  v-if="form && !switcher.checkbox"
                  :key="'wizard-' + step"
                  ref="formComponent"
                  v-model="integration[step]"
                  :config="formConfig"
                  :form="form"
                  @change="onChange"
                />
                <!--      START: CREDENTIALS-TEST-BUTTON      -->
                <div v-if="step === 'credentials'" class="w-100">
                  <OAuth
                    v-if="
                      authModeToggle
                        ? switcher.checkbox
                        : store.getters.appConfig?.useOAuth
                    "
                    :integration="integration"
                  ></OAuth>
                  <!-- Render the button if OAuth is not rendered -->
                  <template
                    v-if="
                      !switcher.checkbox || !store.getters.appConfig?.useOAuth
                    "
                  >
                    <button
                      class="btn btn-primary wizard-button my-3"
                      @click="validateCredentials"
                    >
                      {{ $t("salesChannelManagementApp.validateCredentials") }}
                    </button>
                  </template>
                </div>
                <div v-else-if="step === 'sftpData'" class="w-100">
                  <button
                    class="btn btn-primary wizard-button my-3"
                    @click="validateSftpData"
                  >
                    {{ $t("salesChannelManagementApp.validateSftpData") }}
                  </button>
                </div>

                <div v-if="!validName" class="mt-6 alert alert-info">
                  {{ $t("salesChannelManagementApp.uniqueName") }}
                </div>
                <!--      END: CREDENTIALS-TEST-BUTTON      -->
              </div>
              <!--      END: GENERAL-CONTENT      -->
            </div>
            <!--      END: WIZARD-CONTENT (RIGHT)      -->

            <!--      START: HELP-CARD (LEFT)      -->
            <div class="info-card-container">
              <div class="info-card m-4 d-flex p-4 justify-content-between">
                <div class="d-flex">
                  <div class="mr-2">
                    <i class="fal fa-circle-info" />
                  </div>
                  <div v-html="$t('salesChannelManagementApp.help')"></div>
                </div>

                <HelpArea :items="helpItems"></HelpArea>
              </div>
            </div>
            <!--      END: HELP-CARD      -->

            <!--      START: BUTTONS (RIGHT)     -->
            <div class="wizard-navigation-container">
              <div
                class="d-flex justify-content-between mx-4 py-4 wizard-navigation"
              >
                <!--      START: BACK-BUTTON      -->
                <button
                  :disabled="currentStep === 0"
                  class="btn btn-back"
                  @click="back"
                >
                  {{ $t("salesChannelManagementApp.back") }}
                </button>
                <!--      END: BACK-BUTTON      -->

                <!--      START: STEPS      -->
                <div class="steps-navigation align-self-center">
                  <span>{{ $t("salesChannelManagementApp.steps") }} </span>
                  <span class="font-weight-bold">{{ currentStep + 1 }} </span>
                  <span>{{ $t("salesChannelManagementApp.of") }} </span>
                  <span class="font-weight-bold"
                    >{{ wizardSteps.length + 1 }}
                  </span>
                </div>
                <!--      START: STEPS      -->

                <!--      START: NEXT/SAVE-BUTTON      -->
                <button
                  :disabled="nextButtonDisabled"
                  class="btn btn-primary"
                  @click="next"
                >
                  <span v-if="currentStep === wizardSteps.length - 1">
                    {{ $t("salesChannelManagementApp.save") }}
                  </span>
                  <span v-else>
                    {{ $t("salesChannelManagementApp.next") }}
                  </span>
                  <i class="fal fa-chevron-right icon-small" />
                </button>
                <!--      END: NEXT/SAVE-BUTTON      -->
              </div>
            </div>
            <!--      END: BUTTONS      -->
          </div>
        </template>
        <!--      END: WIZARD      -->
      </Card>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.wizard-container {
  .wizard {
    display: grid;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    .wizard-card {
      height: 100vh;
      max-height: 80%;
      width: 100vw;
      max-width: 85%;
      margin: 0 auto;
      overflow: hidden;

      .wizard-grid {
        display: grid;
        grid-template-columns: 5fr 7fr;
        grid-template-rows: 5fr 1fr;

        .steps-navigation {
          display: none;
        }

        @media screen and (max-width: 920px) {
          grid-template-columns: 1fr;
          grid-template-rows: 6fr 1fr;

          .side-navigation,
          .info-card-container {
            display: none;
          }

          .steps-navigation {
            display: block;
          }
        }
      }
    }

    .btn-back {
      color: #344054;

      &:disabled {
        opacity: 0.6;
        filter: grayscale(1);
      }
    }

    .wizard-navigation {
      border-top: 1px solid #d0d5dd;
    }

    .side-navigation {
      background-color: #f9fafb;
      border-right: 1px solid #d0d5dd;
      height: 100%;

      .side-nav-content {
        .wizard-dot-lines {
          min-height: 80px;
          overflow: hidden;
        }
      }
    }

    .info-card-container,
    .wizard-navigation-container {
      display: grid;
      align-content: end;
    }

    .info-card-container {
      background-color: #f9fafb;
      border-right: 1px solid #d0d5dd;

      .info-card {
        background-color: #eaecf0;
        border-radius: 6px;
      }
    }
  }
}

i {
  color: $color-connect-text;
}

.dashed-line {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%2316B364FF' stroke-width='2' stroke-dasharray='6%2c 11' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
  height: 300px;
  left: 50%;
  position: absolute;
  width: 1px;
}

.display-grid {
  align-content: space-between;
  display: grid;
}

.hyphen-auto {
  hyphens: auto;
}

.icon-small {
  font-size: 1rem;
}

.inactive {
  filter: grayscale(1);
  opacity: 0.6;
}

.wizard-logo {
  height: 100%;
  max-height: 40px;
}
</style>
